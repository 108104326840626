.root {
  composes: wrapperPadding from '../../index.css';
  position: relative;
  padding-top: 42px;
  padding-bottom: 42px;
  margin-top: 64px;
  background: rgb(var(--color-primary-light3));
}

.container {
  composes: wrapperMaxWidth from '../../index.css';
  display: grid;
  grid-template-columns: 1fr;
}

.title {
  margin: 0;
  color: rgb(var(--color-text-textHeadlines));
  text-align: center;
  font-family: 'Recoleta', sans-serif;
  font-size: 36px;
  font-weight: 400;
}

.containerBtn {
  display: flex;
  flex-direction: column;
  margin-top: 27px;
  align-items: center;
}

.containerBtn span {
  display: inline-block;
  margin-bottom: 8px;
  font-size: 10px;
  color: rgb(var(--color-primary-darker2));
}

.btn {
  max-width: 280px;
}

.circle {
  position: absolute;
  left: 0;
  top: 84px;
  z-index: -1;
}

.heart {
  position: absolute;
  right: 0;
  top: 12px;
  z-index: -1;
}

.imgsContainer {
  display: none;
}

@media (min-width: 768px) {
  .container {
    grid-template-columns: 1fr 1fr;
    min-height : 505px;
    place-content: center;
  }

  .title {
    text-align: left;
    font-size: 48px;
  }

  .containerBtns {
    display: grid;
    grid-template-columns: max-content max-content;
    column-gap: 16px;
    margin-top: 24px;
  }

  .containerBtn span {
    text-align: left;
    width: 100%;
  }

  .circle {
    display: none;
  }
  
  .heart {
    display: none;
  }

  .shapeDesktop1, .shapeDesktop2 {
    position: absolute;
  }

  .shapeDesktop1 {
    left: 0;
    width: 180px;
    z-index: 1;
  }

  .shapeDesktop2 {
    right: 0;
    top: 10px;
    width: 400px;
  }

  .imgsContainer {
    display: block;
  }

  .imgsContainer .mobileImg, .imgsContainer .desktopImg {
    position: absolute;
  }

  .imgsContainer .mobileImg {
    right: 180px;
    top: 140px;
    z-index: 1;
    width: 150px;
  }

  .imgsContainer .desktopImg {
    right: 0;
    top: 60px;
    width: 300px;
  }
}

@media (min-width: 1024px) {
  .root {
    min-height : 605px;
  }

  .imgsContainer .mobileImg {
    right: 240px;
    width: 200px;
  }

  .imgsContainer .desktopImg {
    right: 0;
    width: 400px;
  }

  .shapeDesktop1 {
    width: 270px;
  }

  .shapeDesktop2 {
    width: 500px;
  }
  
  .title {
    font-size: 64px;
  }
}

@media (min-width: 1280px) {
  .imgsContainer .mobileImg {
    width: 238px;
    right: 320px;
  }
  
  .imgsContainer .desktopImg {
    width: 509px;
  }

  .shapeDesktop2 {
    width: 684px;
  }
}