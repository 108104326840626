.root {
  padding: 0;
  position: relative;
  /* padding-top: 42px;
  padding-bottom: 42px; */
  margin-top: 64px;
  background: rgb(var(--color-primary-light3));
}

.container {
  composes: wrapperMaxWidth from '../../index.css';
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  align-items: center;
  grid-template-areas: 'info'
                        'imgs'
                        'btns';
}

.containerInfo {
  composes: wrapperPadding from '../../index.css';
  padding-top: 42px;
  grid-area: info;
}

.containerTexts {
  text-align: center;
  font-weight: 400;
}

.containerTexts__others {
  composes: containerTexts;
  max-width: 553px;
}

.containerTexts h2 {
  font-size: 13.9285px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 12px;
  font-family: 'Nunito', sans-serif;
  color: rgb(var(--color-primary-darker2));
}

.containerTexts h1 {
  margin: 0;
  color: rgb(var(--color-text-textHeadlines));
  font-size: 36px;
  font-weight: 400;
}

.containerBtns {
  grid-area: btns;
  composes: wrapperPadding from '../../index.css';
  padding-bottom: 42px;
}

.containerBtn {
  display: flex;
  flex-direction: column;
  margin-top: 27px;
  align-items: center;
}

.containerBtn span {
  display: inline-block;
  margin-bottom: 8px;
  font-size: 10px;
  color: rgb(var(--color-primary-darker2));
}

.btn {
  max-width: 280px;
  z-index: 1;
}

.circle {
  position: absolute;
  left: 0;
  top: 84px;
  z-index: -1;
}

.heart {
  position: absolute;
  right: 0;
  top: 12px;
  z-index: -1;
}

.containerImgs {
  grid-area: imgs;
}

@media (min-width: 768px) {
  .container {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'info imgs'
                         'btns imgs';
    grid-template-rows: max-content max-content;
    min-height : 505px;
    place-content: center;
  }

  .containerInfo {
    align-self: flex-end;
  }

  .containerTexts  {
    text-align: left;
  }

  .containerBtns {
    display: grid;
    grid-template-columns: max-content max-content;
    column-gap: 16px;
    margin-top: 24px;
    align-self: flex-start;
  }

  .containerBtn span {
    text-align: left;
    width: 100%;
  }

  .shapeDesktop1, .shapeDesktop2 {
    position: absolute;
  }

  .shapeDesktop1 {
    left: 0;
    width: 180px;
    z-index: 1;
  }

  .imgsContainer {
    display: block;
  }

  .imgsContainer img {
    position: absolute;
  }
}

@media (min-width: 1024px) {
  .root {
    min-height : 605px;
  }

  .shapeDesktop1 {
    width: 270px;
  }
}