.root {
  position: fixed;
  top: 0;
  z-index: 3;
  background: rgb(var(--color-primary-light3));
  width: 100%;
  transition: .25s;
}

.scroll {
  background: rgb(var(--color-white));
  transition: .25s;
}

.container {
  composes: wrapperPadding from '../../index.css';
  composes: wrapperMaxWidth from '../../index.css';
  display: flex;
  grid-template-areas: 'logo hambuguer';
  align-items: center;
  height: 64px;
}

.logo {
  grid-area: logo;
  justify-self: flex-start;
  padding: 6.5px 10px;
  cursor: pointer;
}

.links {
  column-gap: 22px;
  display: flex;
  grid-area: links;
  grid-template-columns: repeat(4, auto);
  margin-left: auto;
  text-decoration: none;
}

.link {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 4px;
  color: rgb(var(--color-primary-primary));
  font-size: 16px;
  font-weight: 500;
  margin-right: 16px;
  text-decoration: none;
  transition: .15s;
  padding: 8px 12px;
}

.link:hover {
  background-color: rgb(var(--color-primary-light3));
}

.link:active {
  background-color: rgb(var(--color-primary-light3-1));
}

.buttons {
  grid-area: buttons;
}

.hamburguerIcon {
  grid-area: hambuguer;
  margin-left: auto;
  padding: 10px;
  cursor: pointer; 
  z-index: 2;
}

@media (min-width: 768px) {
  .container {
    grid-template-areas: 'logo links buttons';
  }

  .links {
    column-gap: 2px;
  }

  .buttons {
    display: flex;
    justify-self: flex-end;
  }

  .buttons button:nth-child(1) {
    margin-right: 12px;
  }
}