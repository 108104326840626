.root {
  display: grid;
  grid-template-areas: 'img' 'texts';
  padding: 26px;
  position: relative;
  border: 1px solid rgb(var(--color-primary-light3-1));
  border-radius: 10px;
}

.root__borderLeft {
  composes: root;
}

.root img {
  grid-area: img;
  display: block;
}

.containerTexts {
  grid-area: texts;
}

.root h5 {
  grid-area: title;
  color: rgb(var(--color-text-textHeadlines));
  font-weight: 400;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  line-height: 130%;
}

.root p {
  grid-area: paragraph;
  color: rgb(var(--color-text-textBody));
  font-size: 15px;
  line-height: 150%;
  margin: 0;
}

@media (min-width: 1024px) {
  .root {
    grid-template-areas: 'imgs texts';
    grid-template-columns: auto 440px;
    justify-content: space-between;
    padding: 40px 0 40px 96px;
    align-items: center;
    border-right: none;
    border-left: 1px solid rgb(var(--color-primary-light3-1));
    border-top: 1px solid rgb(var(--color-primary-light3-1));
    border-bottom: 1px solid rgb(var(--color-primary-light3-1));
    border-radius: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    /* min-height: 384px; */
  }

  .root::before {
    position: absolute;
    content: '';
    height: 1px;
    background: rgb(var(--color-primary-light3-1));
    top: -1px;
    right: 0;
    width: 100vw;
    transform: translateX(80%);
  }
  
  .root::after {
    position: absolute;
    content: '';
    height: 1px;
    background: rgb(var(--color-primary-light3-1));
    bottom: -1px;
    right: 0;
    width: 100vw;
    transform: translateX(80%);
  }

  .root__borderLeft {
    grid-template-areas: 'texts imgs';
    grid-template-columns: 457px auto;
    padding: 40px 96px 40px 0;
    border-left: none;
    border-radius: 0;
    border-right: 1px solid rgb(var(--color-primary-light3-1));
    border-top: 1px solid rgb(var(--color-primary-light3-1));
    border-bottom: 1px solid rgb(var(--color-primary-light3-1));
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .root__borderLeft::before {
    transform: translateX(-50%);
  }
  
  .root__borderLeft::after {
    transform: translateX(-50%);
  }

  .root img {
    /* width: 370px; */
    /* margin: auto; */
  }

  .root h5 {
    color: rgb(var(--color-text-textHeadlines));
    font-weight: 400;
    font-size: 37.5px;
    margin-top: 0;
    margin-bottom: 16px;
  }
  
  .root p {
    color: rgb(var(--color-text-textBody));
    font-size: 18px;
    max-width: 457px;
  }
}