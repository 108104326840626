.container {
  composes: wrapperPadding from '../../index.css';
  composes: wrapperPaddingTBSection from '../../index.css';
  composes: wrapperMaxWidth from '../../index.css';
}

.title {
  margin: 0;
  margin-bottom: 6px;
}

.sectionName {
  font-size: 18px;
  color: rgb(var(--color-text-textBody));
  opacity: 0.8;
}

@media (min-width: 1024px) {
  .title {
    margin-bottom: 21.42px;
  }

  /* .bgBottom {
    height: 220px;
    width: 100%;
    background-color: rgb(var(--color-primary-light3));
  } */
}