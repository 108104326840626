@import-normalize; /* bring in normalize.css styles */
@font-face {
  font-family: Recoleta;
  src: url(./fonts/Recoleta-Regular.ttf);
}
@font-face {
  font-family: Nunito;
  src: url(./fonts/Nunito-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: Nunito;
  src: url(./fonts/Nunito-Medium.ttf);
  font-weight: 500;
}

/* sm	640px	@media (min-width: 640px) { ... }
md	768px	@media (min-width: 768px) { ... }
lg	1024px	@media (min-width: 1024px) { ... }
xl	1280px	@media (min-width: 1280px) { ... }
2xl	1536px	@media (min-width: 1536px) { ... } */

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --color-primary-primary: 106, 108, 140;
  --color-primary-darker1: 81, 80, 120;
  --color-primary-darker2: 66, 64, 101;
  --color-primary-light2: 163, 166, 186;
  --color-primary-light3-1: 199, 200, 214;
  --color-primary-light3: 248, 248, 254;
  --color-primary-light4: 233, 233, 238;
  --color-text-textHeadlines: 54, 62, 69;
  --color-text-textBody: 84, 91, 97;
  --color-text-1: 0, 0, 0;
  --color-accent2-accent2: 253, 205, 93;
  --color-border: 177, 177, 189;
  --color-white: 255, 255, 255;
  --color-primary-gradient: linear-gradient(99.26deg, #515078 22.65%, #6A6C8C 99.83%);
  --box-shadow: 0px 4px 8px rgba(13, 34, 68, 0.18);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapperPadding {
  padding-left: 16px;
  padding-right: 16px;
}

.wrapperPaddingTBSection {
  padding-top: 44px;
  padding-bottom: 44px;
}

.bgPrimaryGradient {
  background: linear-gradient(99.26deg, #515078 22.65%, #6A6C8C 99.83%);
}

@media (min-width: 768px) {
  .wrapperPadding {
    padding-left: 26px;
    padding-right: 26px;
  }

  .wrapperPaddingTBSection {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}

@media (min-width: 1024px) {
  .wrapperPadding {
    padding-left: 38px;
    padding-right: 38px;
  }

  .wrapperPaddingTBSection {
    padding-top: 94px;
    padding-bottom: 94px;
  }
}

.wrapperMaxWidth {
  max-width: 1146px;
  margin: auto;
}

.wFull {
  width: 100%;
}

/* General */

h1, h2, h3, h4, h5 {
  font-family: 'Recoleta',  sans-serif;
}

p, span {
  font-family: 'Nunito',  sans-serif;
}

img {
  pointer-events: none;
}

.slick-list {
  min-height: 425px !important;
  overflow: visible !important;
}