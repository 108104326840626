.root {
  position: relative;
}

.container {
  composes: wrapperPadding from '../../index.css';
  composes: wrapperPaddingTBSection from '../../index.css';
  composes: wrapperMaxWidth from '../../index.css';
}

.slider {
  z-index: 2;
}

.card {
  background: rgb(var(--color-primary-darker1));
  padding: 36px;
  border-radius: 9px;
  color: rgb(var(--color-white));
  height: 100%;
}

.card:last-child {
  margin: 9px;
}

.text {
  margin: 0;
  font-size: 14.92px;
  margin-bottom: 24px;
}

.quotation {
  font-size: 37.8059px;
  display: block;
}

.quotation__top {
  composes: quotation;
}

.quotation__bottom {
  composes: quotation;
  text-align: right;
}

.card address {
  font-size: 13.96px;
  display: grid;
  gap: 6px;
  font-style: normal;
}

.card address span:first-child {
  font-weight: 700;
}

.shape1, .shape2  {
  display: none;
}

.shape2  {
  z-index: 1;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 18px;
}

.buttons button {
  background: none;
  border: none;
  cursor: pointer;
}

@media (min-width: 768px) {
  .card {
    margin: 0 9px;
  }

  .text {
    height: 150px;
  }

  .buttons {
    margin-top: 36px;
  }

  .shape1, .shape2  {
    position: absolute;
    display: block;
  }

  .shape1 {
    top: -160px;
    left: 0;
    width: 180px;
  }
  
  .shape2 {
    bottom: -150px;
    right: 0;
    width: 180px;
  }
}

@media (min-width: 1024px) {
  .shape1, .shape2  {
    width: initial;
  }

  .shape1 {
    top: -220px;
    left: 0;
  }
  
  .shape2 {
    bottom: -250px;
    right: 0;
  }
}