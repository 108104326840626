.root {
  background: rgb(var(--color-primary-light3));
  position: relative;
}

.root p {
  padding: 44px 55px;
  font-size: 22px;
  line-height: 130%;
  color: rgb(var(--color-text-textHeadlines));
  max-width: 752px;
  margin: auto;
  text-align: center;
}

.root img {
  display: none;
}

@media (min-width: 768px) {
  .root img {
    display: block;
    position: absolute;
    right: 0;
    bottom: -80px;
    width: 100px;
  }
}

@media (min-width: 1024px) {
  .root p {
    padding: 120px 55px;
    font-size: 32px;
    line-height: 150%;
    margin: auto;
  }

  .root img {
    width: 163px;
  }
}