.root {
  padding: 14px 32px;
  border-radius: 4px;
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  cursor: pointer;
  line-height: 22px;
}

.root:hover {
  opacity: 0.85;
}

.withRightIcon {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  column-gap: 6px;
}

.primary {
  background: rgb(var(--color-primary-darker1));
  color: rgb(var(--color-white));
  border: 1px solid transparent;
}

.accent2 {
  background: rgb(var(--color-accent2-accent2));
  color: rgb(var(--color-text-textHeadline));
  border: 1px solid transparent;
}

.accent2__text {
  background: transparent;
  color: rgb(var(--color-accent2-accent2));
  border: 1px solid transparent;
}

.other {
  background: rgb(var(--color-white));
  color: rgb(var(--color-text-textHeadlines));
  border: 1px solid rgb(var(--color-border));
}

@media (min-width: 768px) {
  .root {
    padding: 8px 16px;
  }
}

.sm {
  padding: 8px 16px;
}