.root {
  border: 1px solid rgb(var(--color-border));
  border-radius: 6px;
  padding: 26px;
}

.root img {
  display: block;
}

.root h5 {
  color: rgb(var(--color-text-textHeadlines));
  font-weight: 400;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  line-height: 130%;
}

.root p {
  color: rgb(var(--color-text-textBody));
  font-size: 15px;
  line-height: 150%;
  margin: 0;
}

@media (min-width: 1024px) {
  .root {
    padding: 32px;
  }

  .root h5 {
    color: rgb(var(--color-text-textHeadlines));
    font-weight: 400;
    font-size: 21.4px;
    margin-top: 28px;
    margin-bottom: 16px;
  }
  
  .root p {
    color: rgb(var(--color-text-textBody));
    font-size: 15px;
  }
}