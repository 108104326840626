.root {
  border-bottom: 1px solid rgb(var(--color-border));
  max-width: 796;
}

.accordion {
  color: rgb(var(--color-text-textHeadlines));
  font-weight: 400;
  font-size: 21.6px;
  padding: 18px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: none;
  border: none;
  cursor: pointer;
}

.accordion span {
  font-family: 'Recoleta', sans-serif;
}

.content {
  color: rgb(var(--color-text-textBody));
  font-size: 15px;
  line-height: 150%;
  margin: 0;
  display: none;
}

.content[data-open="true"] {
  display: block;
  padding-bottom: 18px;
}

@media (min-width: 1024px) {
  .accordion {
    font-size: 44.79px;
    padding: 32px 0;
  }

  .content {
    color: rgb(var(--color-text-textBody));
    font-size: 21.6px;
  }

  .content[data-open="true"] {
    padding-bottom: 32px;
  }
}