.containerImgs {
  position: relative;
  overflow: hidden;
  height: 470px;
}

.img1 {
  display: none;
  position: relative;
  z-index: 2;
  left: 10px;
  top: 300px;
}

.img2 {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%) !important;
  width: 100%;
  z-index: 1;
}

.shape {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 768px) {
  .containerImgs {
    height: 460px;
  }
  
  .shape {
    right: 0;
    top: 10px;
    width: 450px;
  }

  .img1 {
    display: inherit;
    left: 0;
    top: 250px;
    width: 130px;
  }

  .img2 {
    right: 0;
    top: 60px;
    width: 400px;
  }
}

@media (min-width: 1024px) {
  .shape {
    width: 550px;
  }

  .img1 {
    left: 0;
    top: 300px;
    width: 152px;
  }

  .img2 {
    right: 0;
    width: 500px;
  }
}

@media (min-width: 1280px) {
  .shape {
    width: 590px;
  }
  
  .img2 {
    width: 500px;
  }
}