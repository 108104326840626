.sectionName, .title {
  text-align: center;
}

.sectionName {
  display: block;
  color: rgb(var(--color-primary-darker2));
  font-size: 12.5px;
}

.title {
  color: rgb(var(--color-text-textHeadlines));
  font-weight: 400;
  font-size: 21.6px;
}

@media (min-width: 1024px) {
  .sectionName, .title {
    text-align: left;
  }

  .sectionName {
    font-size: 12.5px;
  }

  .title {
    font-size: 37.3px;
  }
}