.root {
  background: rgb(var(--color-white));
  height: calc(100vh - 120px);
  width: calc(100vw - 96px);
  z-index: 1;
  position: fixed;
  right: -100%;
  top: 0;
  padding: 72px 48px 48px 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: right .3s ease-in-out;
}

.root__open {
  right: 0;
}

.logo {
  text-align: center;
}

.link {
  display: block;
  color: rgb(var(--color-text-textHeadlines));
  text-decoration: none;
  font-size: 36px;
  font-family: 'Recoleta', sans-serif;
  padding: 8px;
  text-align: center;
}

.btnLoginContainer {
  margin-top: 50px;
}

.btnLoginContainer span {
  margin-bottom: 10px;
  display: block;
  text-align: center;
}