.root {
  composes: wrapperPadding from '../../index.css';
  composes: wrapperMaxWidth from '../../index.css';
  composes: wrapperPaddingTBSection from '../../index.css';
  position: relative;
}

.title {
  margin-top: 8px;
  margin-bottom: 44px;
}

.cards {
  display: grid;
  gap: 18px;
}

.btn {
  width: 100%;
  margin-top: 20px;
  display: none;
}

@media (min-width: 640px) {
  .btn {
    width: auto;
    /* display: block; */
    display: none;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1024px) {
  .title {
    margin-top: 14px;
    margin-bottom: 54px;
  }

  .cards {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }

  .btn {
    margin-top: 56px;
  }
}