.root {
  background: rgb(var(--color-primary-darker2)); 
  border-radius: 8px;
  padding: 26px 22px;
  color: rgb(var(--color-white));
  transition: all .2s;
  z-index: 1;
}

.root:hover {
  transform: translatey(-8px);
  box-shadow: var(--box-shadow);
}

.root img {
  display: block;
}

.root a {
  text-decoration: none;
}

.title {
  display: grid;
  grid-template-columns:  auto 1fr;
  gap: 16px;
  align-items: center;
  margin-bottom: 16px;
}

.root h5 {
  font-weight: 400;
  font-size: 25.92px;
  margin-top: 0;
  margin-bottom: 0;
}

.root p {
  font-size: 15px;
  line-height: 150%;
  margin: 0;
  margin-bottom: 16px;
}

.btn {
  padding-left: 0;
}

@media (min-width: 1024px) {
  .root {
    padding: 32px;
    max-width: calc(264px - 32px);
  }

  .root h5 {
    font-size: 25.92px;
  }
}