.root {
  composes: wrapperPadding from '../../index.css';
  composes: wrapperMaxWidth from '../../index.css';
  composes: wrapperPaddingTBSection from '../../index.css';
  position: relative;
}

.title {
  margin-top: 8px;
  margin-bottom: 44px;
}

.cards {
  display: grid;
  gap: 18px;
}

.btn {
  width: 100%;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .btn {
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1024px) {
  .title {
    margin-top: 14px;
    margin-bottom: 54px;
  }

  .cards {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .btn {
    margin-top: 56px;
  }
}