.root {
  composes: wrapperPadding from '../../index.css';
  position: relative;
  padding-top: 26px;
}

.container {
  composes: wrapperMaxWidth from '../../index.css';
}

.title {
  margin-top: 8px;
  margin-bottom: 44px;
}

.accordions {
  max-width: 796px;
  margin: auto;
}

.shape1, .shape2 {
  display: none;
}

@media (min-width: 640px) {
  .btn {
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1024px) {
  .root {
    padding-top: 62px;
    padding-bottom: 96px;
  }

  .title {
    margin-top: 14px;
    margin-bottom: 54px;
  }

  .shape1, .shape2 {
    display: block;
  }

  .shape1 {
    position: absolute;
    left: 0;
    top: 30px;
    width: 100px;
  }

  .shape2 {
    position: absolute;
    right: 0;
    bottom: -120px;
    width: 130px;
    z-index: 1;
  }
}

@media (min-width: 1280px) {
  .shape1 {
    width: auto;
  }

  .shape2 {
    width: auto;
  }
}