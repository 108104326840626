.root {
  composes: bgPrimaryGradient from '../../index.css';
  composes: wrapperPaddingTBSection from '../../index.css';
  position: relative;
}

.textsContainer {
  composes: wrapperPadding from '../../index.css';
  composes: wrapperMaxWidth from '../../index.css';
  grid-area: texts;
}

.sectionName {
  color: rgb(var(--color-white));
}

.title {
  color: rgb(var(--color-white));
  margin-top: 8px;
  margin-bottom: 36px;
}

.paragraph {
  color: rgb(var(--color-white));
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  margin: 0;
  opacity: 0.8;
}

.textsContainer .paragraph:first-of-type {
  margin-bottom: 22px;
}

.imgContainer {
  grid-area: img;
}

.img {
  display: block;
  margin-top: 24px;
  margin-bottom: 16px;
  max-width: 561px;
  position: relative;
  z-index: 1;
}

.img img {
  width: 100%;
}

.btnContainer {
  composes: wrapperPadding from '../../index.css';
  composes: wrapperMaxWidth from '../../index.css';
  grid-area: btn;
}

.btn {
  width: 100%;
}

.shapeMobile1, .shapeMobile2 {
  position: absolute;
}

.shapeMobile1 {
  left: 0;
  top: 90px;
}

.shapeMobile2 {
  right: 0;
  top: 400px;
}

@media (min-width: 640px) {
  .container {
    display: grid;
    grid-template-areas: 'texts'
                         'btn'
                         'img';
  }

  .imgContainer {
    max-width: 1146px;
    margin: auto;
    padding-left: 38px;
    padding-right: 38px;
    max-width: 780px;
  }

  .img {
    margin-top: 82px;
  }

  .btnContainer {
    margin: initial;
  }

  .btn {
    width: auto;
    margin-top: 42px;
  }

  .shapeMobile1 {
    left: 0;
    top: 20px;
  }
  
  .shapeMobile2 {
    right: auto;
    left: calc(50% - 350px);
    bottom: 0;
    top: auto;
    max-width: 700px;
  }
  
}

@media (min-width: 1024px) {
  .root {
    padding-top: 0;
    padding-bottom: 0;
  }
  .container {
    grid-template-areas: 'texts img'
                         'btn   img';
    grid-template-rows: auto max-content;
    grid-template-columns: 1fr max-content;

    max-width: 1146px;
    margin: auto;
    padding-left: 38px;
    padding-right: 38px;
  }

  .textsContainer {
    padding-left: 0;
    /* padding-right: 128px; */
    padding-top: 96px;
    /* max-width: 360px; */
    margin: initial;
  }

  .btnContainer {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 96px;
  }

  .imgContainer {
    position: relative;
    padding: 0;
    height: 100%;
  }

  .img {
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
  }

  .img img {
    height: 100%;
  }

  .shapeDesktop1, .shapeDesktop2 {
    position: absolute;
  }
  
  .shapeDesktop1 {
    left: -100px;
    bottom: 0;
  }
  
  .shapeDesktop2 {
    right: -100px;
    top: 20px;
  }

  .title {
    margin-top: 14px;
    margin-bottom: 18px;
  }

  .btnContainer {
    margin: initial;
  }

  .btn {
    margin-top: 48px;
    margin-left: initial;
    margin-right: initial;
  }
}

@media (min-width: 1280px) {
  .textsContainer {
    max-width: 460px;
  }
}