.containerImgs {
  position: relative;
  overflow: hidden;
  height: 470px;
}

.img1 {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 220px;
}

.img2 {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  width: 230px;
}

.shape {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 510px;
}

@media (min-width: 640px) {
  .img1 {
    left: 80px;
  }
  
  .img2 {
    right: 80px;
  }
  
}

@media (min-width: 768px) {
  .containerImgs {
    height: 605px;
  }
  
  .shape {
    right: 0;
    top: 10px;
    width: 450px;
  }

  .img1 {
    left: 100px;
    top: 80px;
    width: 180px;
  }

  .img2 {
    left: 320px;
    bottom: 80px;
    width: 200px;
  }
}

@media (min-width: 1024px) {
  .shape {
    width: 550px;
  }

  .img1 {
    right: 250px;
    width: 240px;
  }

  .img2 {
    right: 0;
    bottom: 0;
    width: 240px;
  }
}

@media (min-width: 1280px) {
  .shape {
    width: 570px;
  }
}