.root {
  background: rgb(var(--color-primary-light3));
  position: relative;
  background: rgb(var(--color-primary-light3));
  border-bottom: 4px solid rgb(var(--color-primary-darker1));
}

.root p {
  padding: 44px 55px;
  font-size: 22px;
  line-height: 130%;
  color: rgb(var(--color-text-textHeadlines));
  max-width: 752px;
  margin: auto;
  text-align: center;
}

.container {
  composes: wrapperPadding from '../../index.css';
  composes: wrapperPaddingTBSection from '../../index.css';
  composes: wrapperMaxWidth from '../../index.css';
  display: grid;
  grid-template-areas: 'info';
  gap: 16px;
  align-items: flex-start;
}

.info {
  grid-area: info;
  display: grid;
  gap: 16px;
  text-align: center;
  justify-items: center;
  font-size: 13px;
  color: rgb(var(--color-text-textHeadlines));
  line-height: 150%;
}

.links {
  display: none;
}

.social {
  display: none;
}

.subfooter {
  display: none;
}

@media (min-width: 768px) {
  .root {
    background: rgb(250, 250, 252);
    border-top: 2px solid rgb(var(--color-primary-light4));
    border-bottom: none;
  }

  .container {
    grid-template-areas: 'info links social';
    grid-template-columns: 307px 1fr auto;
    display: grid;
  }

  .info {
    text-align: left;
    justify-items: flex-start;
  }

  .links {
    grid-area: links;
    text-decoration: none;
    display: grid;
    grid-template-rows: repeat(4, auto);
    gap: 6px;
    justify-content: center;
  }
  
  .link {
    text-decoration: none;
    color: rgb(var(--color-text-textHeadlines));
    font-weight: 500;
    padding: 4px;
  }

  .link:hover {
    opacity: 50%;
  }

  .social {
    grid-area: social;
    display: grid;
    gap: 6px;
  }

  .social a {
    padding: 4px;
  }

  .social a:hover {
    opacity: 50%;
  }

  .rootSubfooter {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), #FAFAFC;;
  }

  .subfooter {
    composes: wrapperPadding from '../../index.css';
    composes: wrapperMaxWidth from '../../index.css';
    font-size: 12px;
    line-height: 18px;
    display: flex;
    justify-content: space-between;
  }

  .terms a:first-child {
    margin-right: 16px;
  }

  /* .terms a:first-child::after {
    content: '|';
    margin-left: 16px;
    color: #D0D0D0;
  } */

  .terms a, .subfooter span {
    display: inline-block;
    padding: 12px 0;
    color: rgb(var(--color-text-1));
  }

  .terms a {
    text-decoration: none;
  }
}

@media (min-width: 1024px) {
  .links {
    grid-template-columns: repeat(4, auto);
    grid-template-rows: none;
    gap: 22px;
  }

  .social {
    grid-template-columns: repeat(3, auto);
    gap: 22px;
  }
}