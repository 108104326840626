.root {
  composes: wrapperPadding from '../../index.css';
  composes: wrapperPaddingTBSection from '../../index.css';
  
  position: relative;
  background: rgb(var(--color-white));
}

.container {
  composes: bgPrimaryGradient from '../../index.css';
  composes: wrapperMaxWidth from '../../index.css';
  padding-top: 32px;
  padding-bottom: 32px;
  box-shadow: var(--box-shadow);
  border-radius: 10px;
}

.sectionName, .title {
  color: rgb(var(--color-white));
}

.title {
  max-width: 232px;
  margin: 8px auto 32px auto;
  font-size: 26px;
}

.cards {
  display: grid;
  padding: 0 24px;
  gap: 26px;
}

.mobileShape1, .mobileShape2 {
  position: absolute;
}

.mobileShape1 {
  bottom: 50px;
  left: 0;
}

.mobileShape2 {
  top: 90px;
  right: 0;
}

@media (min-width: 640px) {
  .title {
    max-width: initial;
  }

  .btn {
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1024px) {
  .root {
    background: rgb(var(--color-primary-light3));
    padding-top: 62px;
    padding-bottom: 96px;
  }

  .container {
    padding: 76px;
    display: grid;
    grid-template-columns: 265px 1fr;
    gap: 24px;
    justify-items: flex-end;
  }

  .title {
    margin-top: 14px;
    margin-bottom: 54px;
    font-size: 37.3px;
  }

  .cards {
    grid-template-columns: 1fr 1fr;
    padding: 0;
  }

  .mobileShape1, .mobileShape2 {
    display: none;
  }
}

@media (min-width: 1280px) {
  .container {
    padding: 96px;
  }
}