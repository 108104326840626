.root {
  composes: wrapperPaddingTBSection from '../../index.css';
  composes: wrapperPadding from '../../index.css';
  background: rgb(var(--color-primary-light3));
  position: relative;
}

.container {
  composes: wrapperMaxWidth from '../../index.css';
  display: grid;
  align-items: center;
}

.title {
  margin-top: 8px;
  margin-bottom: 36px;
}

.items {
  display: grid;
  gap: 36px;
}

.item {
  display: grid;
  grid-template-columns: 35px 1fr;
  gap: 18px;
}

.itemNumber {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(var(--color-primary-light4));
  color: rgb(var(--color-primary-primary));
}

.itemNumber__active {
  background: #FEF0EA;
  color: #F06500;
}

.item h5, .item p {
  font-size: 14.92px;
  line-height: 150%;
}

.item h5 {
  font-weight: 700;
  font-family: 'Nunito', sans-serif;
  color: rgb(var(--color-text-textHeadlines));
  margin: 0;
  height: 35px;
  display: flex;
  align-items: center;
}

.item p {
  color: rgb(var(--color-text-textBody));
  margin: 10px 0;
}

.item picture {
  grid-column: 1 / 3;
  margin: auto;
}

.contentImgs {
  display: none;
}

@media (min-width: 1024px) {
  .root {
    display: grid;
  }

  .container {
    grid-template-columns: 462px 1fr;
    gap: 140px;
  }

  .items {
    display: grid;
    gap: 16px;
    max-width: 400px;
  }

  .item {
    cursor: pointer;
  }
  
  .item h5 {
    height: auto;
  }

  .item picture {
    display: none;
  }

  .contentImgs {
    display: block;
    margin-top: 40px;
  }

  .contentImgs img {
    width: 544px;
    max-width: 100%;
  }
}